import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import ComponentTextblock3 from "../../../components/component-textblock-3";
//import Slider from "react-slick";
import ComponentProductHalJoint from "../../../components/main/component-product-hal-joint";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  //const settings = {
  //  dots: true,
  //  infinite: false,
  //  speed: 500,
  //  //autoplay: true,
  //  autoplaySpeed: 4000,
  //  arrows: false,
  //  slidesToShow: 1,
  //  //centerPadding: '100px',
  //  focusOnSelect: true,
  //  //centerMode: true,
  //  variableWidth: true,
  //  responsive: [
  //    {
  //      breakpoint: 896,
  //      settings: {
  //        centerMode: false,
  //      },
  //    },
  //  ],
  //};

  return (
    <Layout location={location} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Advances in wearable technology for acute rehabilitaion - HAL Single Joint`
            : `Neueste Technologie für die akut Rehabilitation - HAL Single Joint`
        }
        description={
          intl.locale === "en"
            ? `The use of HAL Single Joint, portable and lightweight technology, allows acute and chronic patients to perform repetition of therapeutic exercises in elbow, knee, and ankle joints.`
            : `HAL Single Joint, eine leichte tragbare Technologie, erlaubt akuten und chronischen Patienten, therapeutischer Übungen von Ellbogen, Knie und Sprunggelenk.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/5OCWShxzPTq3lA5RBIeFMj/fcb046d332fda082ab1844d3b028be61/E171.png?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-hellblau">
        <StaticImage
          src="../../../images/products/hal-joint/header-single-joint@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../../images/products/hal-joint/header-single-joint-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />
        <div className="container flex-row my-7">
          <div className="flex-column-6">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Repetition of therapeutic exercises leads to improved functional
  outcomes`
                : `Wiederholung therapeutischer Übungen führen zu verbesserten funktionellen Resultaten`}
            </h1>
            <p className="message-label-bold txt txt-rot">HAL Single Joint</p>
            <p>
              {intl.locale === "en"
                ? `Its portable and lightweight design makes it possible to start HAL
  therapy in the super-acute phase. Utilising HAL Single Joint aims
  to accelerate recovery and improve prognosis`
                : `Das portable und leichte Design ermöglicht es die HAL-Therapie schon in der Frühphase einzusetzten. Der Einsatz des HAL Single Joints zielt darauf ab die Genesung zu beschleunigen und die Prognose zu verbessern `}
            </p>
          </div>
        </div>
      </section>
      <section className="sect p-0">
        <div className="container flex-row mt-9 flex-wrap">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `HAL Single Joint helps you train a specific joint to increase the
  mobility of your lower and upper extremities.`
                : `HAL Single Joint hilft Ihnen, ein einzelnes Gelenk zu trainieren, um die Beweglichkeit Ihrer unteren und oberen Extremitäten zu verbessern. `}
            </h2>
            <p className="mb-2">
              {intl.locale === "en"
                ? `The small size of the system allows it to be used while in bed
  during the early stages of recovery from surgery or even at home
  to continue making progress. Here’s how HAL Single Joint will be
  able to help:`
                : `Dank der geringen Größe des Systems kann es in der Frühphase der Genesung nach einer Operation im Bett oder sogar zu Hause verwendet werden, um weitere Fortschritte zu erzielen. So kann der HAL Single Joint Ihnen helfen:`}
            </p>
            {intl.locale === "en" ? (
              <ul className="mb-2">
                <li>Control extension and flexion of paralyzed joints.</li>
                <li>
                  Focus on specific joints such as the knee, ankle, and elbow.
                </li>
                <li>
                  Display the monitored neurological signals and manipulate the
                  device's output to meet the needs of the patient.
                </li>
                <li>
                  We envision using this non-invasive device in the ICU or
                  during inpatient stay, as well as at home after users have
                  received training at a medical facility.
                </li>
              </ul>
            ) : (
              <ul className="mb-2">
                <li>
                  Kontrolle der Streckung und Beugung von gelähmten Gelenken
                </li>
                <li>
                  Konzentration auf bestimmte Gelenke wie Knie, Sprunggelenk und
                  Ellbogen
                </li>
                <li>
                  Anzeige der beobachteten neurologischen Signale und Anpassung
                  der Geräteleistung an die Bedürfnisse des Patienten.
                </li>
                <li>
                  Wir stellen uns vor, dass dieses nichtinvasive Gerät auf der
                  Intensivstation oder während eines stationären Aufenthalts
                  sowie zu Hause eingesetzt werden kann. Zuvor ist eine Schulung
                  des Benutzers in einer medizinischen Einrichtung notwendig
                </li>
              </ul>
            )}

            <h2 className="txt txt-rot">
              {intl.locale === "en"
                ? `HAL Monitor for Better Care Visualizes information of the body on
  the monitor`
                : `HAL Monitor für eine bessere Betreuung Visualisiert Informationen über den Körper auf dem Bildschirm`}
            </h2>

            <p className="mb-6">
              {intl.locale === "en"
                ? `Monitor for HAL Single Joint Type supports efficient treatment by
  visualizing the strength of bio-electrical signals, the target
  number of exercises, and comparison with past sessions`
                : `Der Monitor für den HAL Single Joint unterstützt eine effiziente Behandlung, indem er die Stärke der bioelektrischen Signale, die Zielanzahl der Übungen und den Vergleich mit früheren Sitzungen visualisiert.`}
            </p>
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Help paretic patients improve their physical functions through
  repeated treatment sessions`
                : `Hilft gelähmten Patienten durch wiederholte Therapieeinheiten, ihre körperlichen Funktionen zu verbessern`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Improve knee range of motion or extension lag for osteoarthritis
  patients`
                : `Verbessert den Bewegungsumfang des Knies und reduziert das aktive Streckdefizit bei Osteoporose Patienten`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Conduct effective joint training with less pain`
                : `Durchführung eines effektives Gelenktrainings mit reduzierten Schmerzen`}
            </p>
          </div>
          <div className="flex-column-12 mt-8">
            <div className="flex-row mx-n2 mb-6 pict-3">
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-joint/image-single-joint-1@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-joint/image-single-joint-2@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
              <div className="px-2">
                <StaticImage
                  src="../../../images/products/hal-joint/image-single-joint-3@2x.png"
                  className="mb-4"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sect pb-0">
        <div className="container">
          <div className="flex-row">
            <div className="flex-column-6">
              <h2 className="txt txt-rot">
                {intl.locale === "en" ? `How It Works:` : `So funktioniert es:`}
              </h2>
              <p>
                {intl.locale === "en"
                  ? `HAL Single Joint senses neurological signals and simultaneously
  assists the wearer’s voluntary movement. In the case of training
  the elbow joint, sensor electrode pads are placed on the skin
  surface at the biceps and the triceps to monitor the
  neurological signals that reach these muscles. When the wearer
  tries to move their elbow, the device detects these neurological
  signals and applies power to the motor attached onto the elbow.
  When your body recognizes the assisted movement of the elbow
  through the feedback to your brain, rewiring takes place through
  neuroplasticity. These changes help to improve control over your
  limbs and reduce abnormal neurological signals.`
                  : `Der HAL Single Joint erfasst neurologische Signale und unterstützt gleichzeitig die willentliche Bewegung des Trägers. Beim Training des Ellenbogengelenks werden Sensor-Elektrodenpads auf der Hautoberfläche am Bizeps und Trizeps angebracht, um die neurologischen Signale zu überwachen, die diese Muskeln erreichen. Wenn der Träger versucht, seinen Ellbogen zu bewegen, erkennt das Gerät diese neurologischen Signale und versorgt den am Ellbogen angebrachten Motor mit Strom. Wenn der Körper die unterstützte Bewegung des Ellenbogens durch die Rückmeldung an das Gehirn erkennt, findet eine Umverknüpfung durch Neuroplastizität statt. Diese Veränderungen tragen dazu bei, die Kontrolle über Ihre Gliedmaßen zu verbessern und anormale neurologische Signale zu reduzieren.`}
              </p>
              <h2 className="txt txt-rot">
                {intl.locale === "en"
                  ? `Indication of use`
                  : `Indikation für die Verwendung`}
              </h2>
              {intl.locale === "en" ? (
                <ul>
                  <li>
                    Improve knee range of motion, especially for the extension
                    side,for osteoarthritis patients who have undergone Total
                    Knee Arthroplasty (TKA) and Opening Wedge High Tibial
                    Osteotomy (OWHTO).
                  </li>
                  <li>
                    Improve the physical function of the joints for patients
                    with paralysis due to impairments in the Central Nervous
                    System, typically caused by Cerebral Vascular Disease,
                    Spinal Cord Injury or Brachial Plexus Injury.
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    Verbesserung des Bewegungsumfangs des Knies, insbesondere
                    der Streckseite, bei Arthrose-Patienten, die sich einer
                    Knietotalendoprothese (TEP) und einer Opening Wedge High
                    Tibial Osteotomy (OWHTO) unterzogen haben.
                  </li>
                  <li>
                    Verbesserung der körperlichen Funktion der Gelenke bei
                    Patienten mit Lähmungen aufgrund von Beeinträchtigungen des
                    zentralen Nervensystems, typischerweise verursacht durch
                    zerebrale Gefäßerkrankungen, Rückenmarksverletzungen oder
                    Verletzungen des Brachialplexus.
                  </li>
                </ul>
              )}

              <p>
                {intl.locale === "en"
                  ? `HAL-Single Joint (HAL-MS01) obtained CE Marking (CE 0197), and
  it was certified its conformity to the requirements of Medical
  Device Directives in EU. Prescription and medical professional
  supervision are required on usage of HAL system.`
                  : `HAL Single Joint (HAL-MS01) hat die CE-Kennzeichnung (CE 0197) erhalten, und seine Konformität mit den Anforderungen der EU-Richtlinien für Medizinprodukte wurde bescheinigt. Für die Verwendung des HAL-Systems sind eine ärztliche Verschreibung und die Überwachung durch medizinisches Fachpersonal erforderlich.`}
              </p>
            </div>
          </div>
        </div>
      </section>
      <ComponentProductHalJoint
        image="/images/products/hal-joint/lumbar-type-slider-image-1@2x.png"
      />
      {/* <section className="sect p-0 sect_Product__non-medical-device-slider">
      <div className="container flex-row sect_Product__non-medical-device-slider__text">
        <div className="flex-column-6 pl-4">
          <p className="fs-6 fwb bt-2-white pt-2 w-300px">The Science</p>
        </div>
        <div className="flex-column-6 mt-8">
          <div className="slide">
          <Slider {...settings}>
            <div className="slide__link">
              <h2 className="fs-11">Hybrid assistive limb (HAL) for Care Support can reduce lumbar load in care deliveries</h2>
              <p className="fs-4">Successful Use of the Hybrid Assistive Limb for Care Support to Reduce Lumbar Load in a Simulated Patient Transfer - The HAL for Care Support significantly reduced subjective lumbar fatigue in a simulated patient transfer. Use of the HAL for Care Support may reduce the occurrence of low back disorders.</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Subjects were able to perform significantly more lifting cycles using HAL</h2>
              <p className="fs-4">Muscle Synergies During Repetitive Stoop Lifting With a Bioelectrically-Controlled Lumbar Support Exoskeleton - The results indicate that muscle coordination patterns are significantly changed, mainly in the timing coefficients of the synergies and marginally changed in synergy weights, when subjects are using an exoskeleton (HAL).</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Biofeedback exercise with HAL-Lumbar Type is a safe and promising treatment for frailty patients</h2>
              <p className="fs-4">Biofeedback Core Exercise Using Hybrid Assistive Limb for Physical Frailty Patients With or Without Parkinson's Disease - Motor dysfunction in Parkinson's disease patients may be partly due to physical frailty, and biofeedback therapy with HAL-Lumbar Type is proposed as a treatment option. Immediate and sustained effects on patients who were refractory to conventional rehabilitation could provide evidence that changes in input to specific receptors by HAL- Lumbar Type contributes to activation of disused neuronal networks and amelioration of arthrogenic muscle inhibition. </p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly reduces cardiopulmonary burden during squat exercise in healthy subjects</h2>
              <p className="fs-4">Effects of a lumbar-type hybrid assistive limb on cardiopulmonary burden during squat exercise in healthy subjects - The oxygen uptake (VO2) and the Borg Scale rating were significantly lower when HAL was used. The HAL significantly reduces cardiopulmonary burden during squat exercise</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly decreased subjective lumbar fatigue</h2>
              <p className="fs-4">The hybrid assistive limb (HAL) for Care Support successfully reduced lumbar load in repetitive lifting movements – HAL- Lumbar Type can decrease lumbar load and improve the lifting performance during repetitive stoop lifting movements in healthy adults.</p>
            </div>
            <div className="slide__link">
              <h2 className="fs-11">Significantly improved the snow-shoveling performance</h2>
              <p className="fs-4">The hybrid assisted limb (HAL) for Care Support, a motion assisting robot providing exoskeletal lumbar support, can potentially reduce lumbar load in repetitive snow-shoveling movements - The reduction of lumbar fatigue and improvement of snow-shoveling performance using HAL for Care Support were statistically significant.</p>
            </div>
          </Slider>
          </div>
        </div>
      </div>
      <div className="bg-rot sect_Product__non-medical-device-slider__bg" />
      <StaticImage src="../../../images/products/motion-principal-big-teaser.png" className="sect_Product__non-medical-device-slider__image"/>
    </section> */}
      <section className="p-0 py-120px position-relative">
        <StaticImage
          src="../../../images/products/hal-joint/image-single-joint@2x.png"
          className="bg-absolute"
        />
        <div className="container">
          <div className="container__inner flex-row txt txt-white">
            <div className="flex-column-6">
              <h2>
                {intl.locale === "en" ? `Specifications` : `Spezifikationen`}
              </h2>
              <div className="flex-row mb-4">
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `External dimension`
                        : `Äußere Abmessungen`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        Length 200mm
                        <br />
                        width 200mm
                        <br />
                        height 944mm *1
                      </p>
                    ) : (
                      <p className="">
                        Länge 200 mm
                        <br />
                        Breite 200mm
                        <br />
                        Höhe 944mm*1
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Weight` : `Approximately`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Gewicht 1.5kg *2`
                        : `ca. 1,5kg*2`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Movable range`
                        : `Beweglicher Bereich`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Extension 0˚/flexion 120˚`
                        : `Auszug 0˚/Biegung 120˚`}
                    </p>
                  </div>
                </div>
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Operating time`
                        : `Betriebsdauer`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Approximately 120 minutes [in normal operation]*3`
                        : `ca. 120 Minuten im Normalbetrieb*3`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Power source` : `Stromquelle`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Custom battery`
                        : `Eigene Batterie`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Robot Suit®` : `Roboteranzug®`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Power unit, control device, controller`
                        : `Antriebseinheit, Steuergerät, Controller`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Accessories` : `Zubehör`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Electrode cables, attachments [upper leg supporter, lower
    leg supporter, upper arm supporter and forearm supporter],
    hip belt, custom battery, custom battery charger*4`
                        : `Elektrodenkabel, Anbauteile (Oberschenkelmanschette, Unterschenkelmanschette, Oberarmmanschette und Unterarmmanschette), Hüftgurt, eigene Batterie, vorgeschriebenes Batterieladegerät*4`}
                    </p>
                  </div>
                </div>
              </div>
              {intl.locale === "en" ? (
                <p>
                  *1 Excluding the control device and the battery, only parts
                  attached to the wearer
                  <br />
                  *2 Excluding the control device and the battery
                  <br />
                  *3 Might change subject to operating statuses and operating
                  environments
                  <br />
                  *4 Might be altered without prior notice
                </p>
              ) : (
                <p>
                  *1 Ohne Steuergerät und Akku, nur am Träger befestigte Teile
                  <br />
                  *2 Ohne Steuergerät und Akku
                  <br />
                  *3 Kann sich je nach Betriebsstatus und Betriebsumgebung
                  ändern
                  <br />
                  *4 Kann ohne vorherige Ankündigung geändert werden
                </p>
              )}
            </div>
            {/* <div className="flex-column-6">
            <StaticImage src="../../../images/products/motion-principal-big-teaser.png" className="sect_Product__non-medical-device-slider__image"/>
          </div> */}
          </div>
        </div>
      </section>
      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}                </Link>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `Technology For A Better, More Mobile Life – HAL`
                : `Technologie für eine besseres, beweglicheres Leben - HAL`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Want to bring mobility technology to your clinic? With a simple
              email to Cyberdyne you can.`
                : `Sie wollen Mobilitätstechnologien in Ihrer Klinik anwenden? Eine einfache E-Mail an Cyberdyne genügt.`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
