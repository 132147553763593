import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  //Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";
import Slider from "react-slick";

const ComponentMain5 = ({ image, children }) => {
  const intl = useIntl();

  const [pos, setPos] = useState(1);
  //const isActiveBenefit = () => {
  //  return pos === 0 ? "active" : "";
  //};
  const isActiveScience = () => {
    return pos === 1 ? "active" : "";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="sect sect_TreatmentCvd_iuh234 bg-white">
      <div className="container mb-0 pb-0">
        <div className="container-block flex-row position-relative bg-rot-important top-border-container">
          <div className="right-all-bg" />
          <div className="flex-column-12">
            <div className="flex-row top-border-wrap mb-8">
              {/* <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveBenefit()}
                  onClick={() => setPos(0)}
                >
                  The Benefits:
                </div>
              </div> */}
              <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveScience()}
                  onClick={() => setPos(1)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `Sciences:` : `Publikationen:`}
                </div>
              </div>
            </div>
          </div>
          <div className="container-block-imgContainer flex-column-6">
            <StaticImage
              src="../../images/products/hal-joint/lumbar-type-slider-image-1@2x.png"
              alt="ImgTreatment"
              loading="eager"
              className="Imgblock01"
            />
          </div>
          <div className="container-block-txtContainer flex-column-6">
            <div className="px-6 py-0">
              {pos === 0 ? (
                <div>
                  <h2 className="h3">
                    The neuromuscular feedback therapy leads to a significant
                    gain in functional locomotion. Other effects which can be
                    more or less pronounced include: Reduction of neuropathic
                    pains, improved bladder and bowl functions, positive
                    improved spasticity symptoms, larger areas with skin
                    sensation resulting in a lower decubitus risk.
                  </h2>
                  <p>
                    • Functional improvement and increase in mobility
                    <br />
                    • Reduced need for assistive devices
                    <br />
                    • Increased walking speed
                    <br />
                    • Increased walking distance
                    <br />
                    • Development of musculature
                    <br />• Increased sensitivity
                  </p>
                  <div>
                    <a
                      href="https://www.tandfonline.com/doi/abs/10.1080/10400435.2016.1219883?journalCode=uaty20"
                      className="link-simple"
                    >
                      {intl.locale === "en" ? `Read` : `Lesen`}
                    </a>
                  </div>
                </div>
              ) : (
                <div className="slide slide-single slide-rot">
                  <Slider {...settings}>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Facilitate early recovery following Total Knee Arthroplasty`
                          : `Erleichterung der frühen Genesung nach Knietotalendoprothese`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The degree of improvement was significantly higher in the robot-assisted rehabilitation group (HAL-Single Joint) for both the extension lag and the visual analog scale score (VAS). The use of HAL-SJ may facilitate early recovery from knee surgery and prevent long-term complications such as quadriceps arthrogenic muscle inhibition.`
                          : `Der Grad der Verbesserung war in der robotergestützten Rehabilitationsgruppe (HAL Single Joint) sowohl bei der Extensionsverzögerung als auch bei der visuellen Analogskala (VAS) signifikant höher. Der Einsatz von HAL SJ kann zu einer schnelleren Erholung nach einer Knieoperation führen und langfristige Komplikationen, etwa eine arthrogene Quadrizepsmuskelhemmung, verhindern.`}
                      </p>
                      <p className="fsi">
                        {intl.locale === "en"
                          ? `"Feasibility of rehabilitation using the single-joint hybrid assistive limb to facilitate early recovery following total knee arthroplasty: A pilot study"`
                          : `"Feasibility of rehabilitation using the single-joint hybrid assistive limb to facilitate early recovery following total knee arthroplasty: A pilot study"`}
                      </p>
                      <div>
                        <a
                          href="https://www.tandfonline.com/doi/abs/10.1080/10400435.2016.1219883?journalCode=uaty20"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Early postoperative period after total knee arthroplasty`
                          : `Frühe postoperative Phase nach einer totalen Knieendoprothese`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `HAL-SJ-based training is safe and effective, and leads to instantaneous improvement of extension lag, without worsening knee joint pain. HAL-SJ-based knee extension training could represent a viable novel post-TKA rehabilitation modality.`
                          : `Das Training mit HAL SJ ist sicher, wirksam und führt zu einer sofortigen Verbesserung der Streckung, ohne die Kniegelenkschmerzen zu verschlimmern. Das Knieextensionstraining mit HAL SJ könnte eine praktikable neue Rehabilitationsmethode nach einer Knie-TEP darstellen.`}
                      </p>
                      <p className="fsi">
                        "Feasibility and efficacy of knee extension training
                        using a single-joint hybrid assistive limb, versus
                        conventional rehabilitation during the early
                        postoperative period after total knee arthroplasty. "
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7788304/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Hybrid assistive limb robots for acute stroke patients`
                          : `Hybride Assistive Limb Robots für akute Schlaganfallpatienten`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `when focusing on effectiveness on the upper extremities, the results between the MR and SR groups can be interpreted as a comparison between the device group (MR) and conventional rehabilitation group (SR). Based on this assumption, the effectiveness of the device to improve the Brunnstrom stage in the upper extremity was shown to be greater than conventional rehabilitation (p=0.025 statistically significant)`
                          : `Wenn man sich auf die Wirksamkeit an den oberen Extremitäten konzentriert, können die Ergebnisse zwischen der MR- und der SR-Gruppe als Vergleich zwischen der HAL-Gruppe (MR) und der konventionellen Rehabilitationsgruppe (SR) interpretiert werden. Ausgehend von dieser Annahme, erwies sich die Wirksamkeit des Gerätes zur Verbesserung des Brunnstrom-Stadiums in der oberen Extremität als größer als die der konventionellen Rehabilitation (p=0,025 statistisch signifikant).`}
                      </p>
                      <p className="fsi">
                        "Tailor-made rehabilitation approach using multiple
                        types of hybrid assistive limb robots for acute stroke
                        patients"
                      </p>
                      <div>
                        <a
                          href="https://www.tandfonline.com/doi/abs/10.1080/10400435.2015.1080768?journalCode=uaty20"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Arm function improved in post chronic stroke patients`
                          : `Verbesserung der Armfunktion bei Patienten nach chronischem Schlaganfal`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The fNIRS study showed that cortical activation increased in the ipsilesional primary sensorimotor area at two weeks and at the four months follow-up. The study showed that the combination of RT and BTX-A therapy was an effective approach for treating spastic hemiplegia due to stroke, and functional imaging study showed neuroplasticity induced by the treatment.`
                          : `Die fNIRS-Studie zeigte, dass die kortikale Aktivierung im ipsiläsionalen primären sensomotorischen Bereich nach zwei Wochen und bei der Nachuntersuchung nach vier Monaten zunahm. Die Studie zeigte, dass die Kombination aus robotergestützter- und BTX-A-Therapie ein wirksamer Ansatz zur Behandlung der spastischen Hemiplegie infolge eines Schlaganfalls war. Die funktionelle Bildgebungsstudie zeigte eine durch die Behandlung induzierte Neuroplastizität.`}
                      </p>
                      <p className="fsi">
                        "Combined therapy using botulinum toxin A and
                        single-joint hybrid assistive limb for upper-limb
                        disability due to spastic hemiplegia "
                      </p>
                      <div>
                        <a
                          href="https://www.jns-journal.com/article/S0022-510X(16)30849-8/fulltext"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Demonstration of interactive biofeedback effect on subacute stroke patients`
                          : `Nachweis der Wirkung von interaktivem Biofeedback bei subakuten Schlaganfallpatienten`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The study possibly demonstrates that HAL assisted rehabilitation immediately induced task related neuroplasticity for upper limb motor function in patients with stroke. In addition, the two different statistical approaches in this study both showed more activation of the motor cortex in the lesioned hemisphere. The present fNIRS finding may implicate that HAL treatment enhances cortical activity in the ipsilesional M1 in response to significant improvement of performance status in patients with subacute stroke.`
                          : `Die Studie zeigt möglicherweise, dass die HAL-unterstützte Rehabilitation sofort eine aufgabenbezogene Neuroplastizität für die motorische Funktion der oberen Gliedmaßen bei Patienten mit Schlaganfall bewirkt. Darüber hinaus zeigten die beiden unterschiedlichen statistischen Ansätze in dieser Studie eine stärkere Aktivierung des motorischen Kortex in der läsionierten Hemisphäre. Das vorliegende fNIRS-Ergebnis könnte darauf hindeuten, dass die HAL-Behandlung die kortikale Aktivität im ipsiläsionalen M1 als Reaktion auf eine signifikante Verbesserung des Leistungsstatus bei Patienten mit subakutem Schlaganfall steigert.`}
                      </p>
                      <p>
                        "Biofeedback effect of hybrid assistive limb in stroke
                        rehabilitation: A proof of concept study using
                        functional near infrared spectroscopy"
                      </p>
                      <div>
                        <a
                          href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0191361"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Home-based robotic therapy in the Chronic Phase of Stroke`
                          : `Heimrobotertherapie in der chronischen Phase des Schlaganfalls`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Home-based robot-assisted therapy along with conventional therapy is a valuable approach to increase paretic upper limb activity and maintain paretic upper limb function in the chronic phase of stroke. Treatment with the device may enhance increasing upper limb activity in chronic stroke patients with paretic upper limbs.`
                          : `Die häusliche robotergestützte Therapie ist neben der konventionellen Therapie ein wertvoller Ansatz zur Steigerung der Aktivität und zum Erhalt der Funktion der oberen Gliedmaßen in der chronischen Phase des Schlaganfalls. Die Behandlung mit dem Gerät kann die Steigerung der Aktivität der oberen Gliedmaßen, verbessern.`}
                      </p>
                      <p className="fsi">
                        "Effects of Home-Based Robotic Therapy Involving the
                        Single-Joint Hybrid Assistive Limb Robotic Suit in the
                        Chronic Phase of Stroke: A Pilot Study"
                      </p>
                      <div>
                        <a
                          href="https://downloads.hindawi.com/journals/bmri/2019/5462694.pdf"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Improvement for upper limb function of subacute phase after Stroke`
                          : `Verbesserung der Funktion der oberen Gliedmaßen in der subakuten Phase nach Schlaganfall`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The results of the present study support the feasibility of HAL assisted neurorehabilitation selection in improving upper limb function in the subacute phase after a stroke. The largest effect was identified among patients with a moderate paralysis of the upper limb at the baseline.`
                          : `Die Ergebnisse der vorliegenden Studie unterstützen die Machbarkeit der HAL-gestützter Neurorehabilitation zur Verbesserung der Funktion der oberen Gliedmaßen in der subakuten Phase nach einem Schlaganfall. Der größte Effekt wurde bei Patienten mit einer mittelschweren Lähmung der oberen Gliedmaßen zu Beginn der Studie festgestellt.`}
                      </p>
                      <p className="fsi">
                        "Feasibility of Robot-assisted Rehabilitation in
                        Poststroke Recovery of Upper Limb Function Depending on
                        the Severity"
                      </p>
                      <div>
                        <a
                          href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7174245/"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentMain5;
